import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgxPaginationModule } from 'ngx-pagination';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

// Directives
import { AmountDirective } from './directives/amount.directive';
import { NumberOnlyDirective } from './directives/number-only.directive';

import { ModalModule } from 'ngx-bootstrap/modal';
import { NoRecordComponent } from '../layout/common/no-record/no-record.component';
import { ReplaceUnderscorePipe } from './pipe/remove-underscore';
import { LightboxModule } from 'ngx-lightbox';
import { NgxScrollToFirstInvalidModule } from '@ismaestro/ngx-scroll-to-first-invalid';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgSelectModule } from '@ng-select/ng-select';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { SeeMoreComponent } from './components/see-more/see-more.component';
import { ChartsModule } from 'ng2-charts';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { CapitalizeFirstDirective } from './directives/first.character.capital.directive';

const directives = [NumberOnlyDirective, AmountDirective, NoRecordComponent, ReplaceUnderscorePipe,
    SeeMoreComponent, CapitalizeFirstDirective];

const imports = [FormsModule, LightboxModule, ReactiveFormsModule, HttpClientModule, NgxPaginationModule,
    NgxScrollToFirstInvalidModule, NgSelectModule, ChartsModule,
    ];

@NgModule({
    declarations: [
        ...directives
    ],
    imports: [
        CommonModule, NgMultiSelectDropDownModule.forRoot(),
        ...imports,
        BsDatepickerModule.forRoot(), AccordionModule.forRoot(),
        ModalModule.forRoot(),
        TabsModule.forRoot()
    ],
    exports: [
        ...directives,
        ...imports,
        BsDatepickerModule, NgMultiSelectDropDownModule, AccordionModule,
        ModalModule,
        TabsModule
    ],
    entryComponents: [],
    providers: []
})
export class SharedModule {
}
