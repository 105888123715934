export const GlobalVariable = {

    SITE_NAME: 'Photo Master Admin',
    PATTERNS: {
        email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    },
    tokenKey: 'supr_student_token'


};

export const CONSTANT = {
    SIDEBARLIST: [
        // {path: 'dashboard', name: 'Dashboard', icon: 'fa fa-flag'},
        // {path: 'club-rules', name: 'Club Rules', icon: 'fa fa-podcast'},
        // {path: 'tutorial-videos', name: 'Tutorial Video', icon: 'fa fa-tumblr-square'},
        // {path: 'exams', name: 'Exams', icon: 'fa fa-university'},
        // {path: 'sessions', name: 'Sessions', icon: 'fa fa-clock-o'},


        { path: 'tutorials', name: 'Tutorials', icon: 'fa fa-podcast' },
        { path: 'users', name: 'Users', icon: 'fa fa-users' },

        { path: 'Courses', name: 'Courses', icon: 'fa fa-sliders' },
        { path: 'lesson', name: 'Lesson', icon: 'fa fa-picture-o' },
        { path: 'expoints', name: 'Experiance Points', icon: 'fa fa-linux' },
        { path: 'levelpoints', name: 'User Level', icon: 'fa fa-futbol-o' },

        { path: 'terms', name: 'Terms of services', icon: 'fa fa-yelp' },
        { path: 'policy', name: 'Privacy Policy', icon: 'fa fa-flag' },
        { path: 'about', name: 'About Us', icon: 'fa fa-linode' },
        { path: 'Reports', name: 'Reported photos', icon: 'fa fa-linode' },
        { path: 'comments', name: 'Reported Comments', icon: 'fa fa-tumblr-square' },
        { path: 'queries', name: 'Queries', icon: 'fa fa-font-awesome' },



    ]
};

